import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {Confetti} from "..";
import * as components from "..";
import "./Challenge.component.scss";

const Challenge = props => {
  const {config: challengeConfig, onChallengeComplete} = props;
  const [burstTimes, setBurstTimes] = useState();
  const [isOnForeFront, bringConfettiForward] = useState();
  const [startTime, setStartTime] = useState();
  const {type, subtype, component, config} = challengeConfig || {};
  const ChallengeInstance = component ? components[component] : "Challenge component is missing";

  useEffect(() => {
    setStartTime(Date.now());
  }, [])

  const onComplete = (completionType) => {
    if (completionType !== "giveup") {
      setBurstTimes(3);
      onChallengeComplete(null, Date.now() - startTime);
      bringConfettiForward(true);
    } else {
      onChallengeComplete(completionType);
    }
  }

  return (
    <div className="c-Challenge w-100 h-100">
      <ChallengeInstance config={config} onComplete={onComplete} />
      <Confetti burstTimes={burstTimes} isOnForeFront={isOnForeFront} />
    </div>
  );
};

Challenge.propTypes = {
  onChallengeComplete: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string
};

export default Challenge;