import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk"
import Immutable from "immutable"
import {createStore, applyMiddleware, compose} from "redux";
import reducer from "./app/reducer";
import Router from "./app/router";
import reportWebVitals from './reportWebVitals';
import './index.scss';

const composeEnhancers =
typeof window === "object" &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  trace: true,
  traceLimit: 25,
  serialize: { // prettier-ignore
    immutable: Immutable
  }
}) : compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Router store={store} />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
