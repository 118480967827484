import React, { useCallback, useEffect, useRef, useState } from "react";
import { Game, GameSelector, Header, Modal, ProgressBar, StarZoomSky } from "../../components";
import Network from "../../utils/network";
import "./Landing.module.scss";

const Landing = props => {
  const [selectedGame, selectGame] = useState();
  const [user, setUser] = useState();
  const [gameLoadProgress, setGameLoadProgress] = useState({progress: 0});
  const ref = useRef();

  const fetchEntities = useCallback((entityConfigs, parent, sub, entityConfig, progressConfig) => {
    const instances = parent[sub];
    if (entityConfig) {
      if (instances && (instances.length === undefined || (instances.length !== undefined && instances.length > 0))) {
        const ids = typeof instances === "object" ? instances.map(ins => ins[entityConfig.contentId || "id"]) : instances;
        Network.post(entityConfig.api, {[entityConfig.payloadKey || "ids"]: ids})
          .then(res => {
            setGameLoadProgress(progressConfig);
            const entities = res.body;
            if (entityConfig.mergeInParent) {
              parent[sub] = typeof parent[sub] === "object" ? {...parent[sub], ...res.body[0]} : res.body[0];
            } else {
              entityConfig.sub = entityConfig.sub ? typeof entityConfig.sub === "object" ? entityConfig.sub : [entityConfig.sub] : []
              entityConfig.sub.forEach(sub => {
                const childEntityConfig = entityConfigs.splice(0, 1)[0];
                ids.forEach(id => {
                  const instance = instances.find(ins => ins[entityConfig.contentId || "id"] === id);
                  const entity = entities.find(entity => entity._id === id);
                  entityConfig.populateFields && entityConfig.populateFields.forEach(field => instance[field] = !instance[field] ? entity[field] : instance[field]);
                  fetchEntities(entityConfigs, instance, sub, childEntityConfig, childEntityConfig.progressConfig)
                });
              });
            }
          });
        }
    }
  }, []);

  useEffect(() => {
    if(!user) {
      setGameLoadProgress({progress: 0, content: "User"});
      Network.get("/user?userId=6441a3b42d37de4bee24624d")
        .then(user => {
          setUser(user.body);
          setGameLoadProgress({progress: 10, content: "Games"});
          fetchEntities([
          {
            api: "/stage/byIds",
            sub: ["clues", "storyline"],
            populateFields: ["storyline", "background"],
            progressConfig: {progress: 40, content: "Clues, Storyline"}
          },
          {
            api: "/clue/byIds",
            sub: "challenge",
            populateFields: ["xlocation", "ylocation", "unlockclues", "challenge", "transitionTimeoutOnComplete"],
            progressConfig: {progress: 60, content: "Challenges"}
          }, 
          {
            api: "/storyline/byId",
            payloadKey: "id",
            mergeInParent: true,
            progressConfig: {progress: 80, content: "Challenges"}
          },
          {
            api: "/challenge/byId",
            payloadKey: "id",
            mergeInParent: true,
            progressConfig: {progress: 100}
          }], user.body, "games", {
            api: "/game/byIds",
            contentId: "gameId",
            populateFields: ["name", "icon"],
            sub: "stages"
          }, {progress: 20, content: "Stages"});
        })
      }
  }, [fetchEntities, user])

  return (
    <div className="c-Landing container" ref={ref}>
      <Header selectedGame={selectedGame} />
      <GameSelector user={user} onGameSelect={selectGame} selectedGame={selectedGame} gameLoadProgress={gameLoadProgress} />
      <Game user={user} selectedGame={selectedGame} gameRef={ref} onBack={selectGame} />
      <ProgressBar progress={gameLoadProgress} />
      <Modal />
      {!selectedGame && <StarZoomSky theme="theme-white" />}
    </div>
  );
};

export default Landing;