import React from "react";
import PropTypes from "prop-types";
import Helper from "../../utils/helper";
import "./ChallengeComplete.component.scss";

const ChallengeComplete = props => {
  const {dispatchModal, timeToComplete, unlockedClues} = props;
  return (
    <div className="c-ChallengeComplete h-100 d-flex justify-content-center align-items-center flex-column">
      <span className="c-ChallengeComplete__hero fw-bold mb-3">Great! Challenge completed successfully</span>
      {unlockedClues && unlockedClues.length > 0 && <h4>You've unlocked {unlockedClues.length} clues</h4>}
      <button className="btn btn-primary d-block fw-bold my-4" onClick={() => {
        dispatchModal({display: false, data: {body: null, switchTo: null, classes: "", bodyClasses: ""}})
      }}>Next&nbsp;&nbsp;{">"}</button>
      <small className="mt-3">Time taken to complete: {Helper.toHumanReadableTime(Math.floor(timeToComplete / 1000))}</small>
    </div>
  );
};

ChallengeComplete.propTypes = {
  dispatchModal: PropTypes.func,
  unlockedClues: PropTypes.array
};

export default ChallengeComplete;