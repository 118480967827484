import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as images from "../../static/images";
import "./Question.component.scss";

const Question = props => {
  const {config, onComplete} = props;
  const [answerSectionDisplayed, displayAnswerSection] = useState();
  const [errorClass, setErrorClass] = useState();
  const [answer, setAnswer] = useState("");
  const iRef = useRef();

  useEffect(() => {
    if (config && iRef.current) {
      iRef.current.focus();
      setTimeout(() => displayAnswerSection(true), 1500);
    }
  }, [config, iRef]);

  const check = () => {
    if (answer.toLowerCase() === config.answer.toLowerCase()) {
      onComplete && onComplete();
    } else {
      setErrorClass("error");
      setTimeout(() => setErrorClass(""), 200);
    }
  }

  return (
    <div className="c-Question h-100 d-flex justify-content-center align-items-center position-relative" style={{background: `url(${images[config.background]})`}}>
      <div className="c-Question__content d-flex justify-content-center align-items-center flex-column">
        {config.question}
        <div className={`c-Question__controls d-flex flex-column ${answerSectionDisplayed ? "mt-5 display" : "overflow-hidden"}`}>
          <input className={`c-Question__answer${errorClass ? " " + errorClass : ""}`}
            ref={iRef} onChange={e => setAnswer(e.target.value)} />
          <div className="c-Question__actions mt-5">
            <button className="btn btn-lg btn-outline-secondary me-4" onClick={() => onComplete("giveup")}>Give Up</button>
            <button className="btn btn-lg btn-outline-primary" onClick={check}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  config: PropTypes.object,
  onComplete: PropTypes.func
};

export default Question;