import React from "react";
import PropTypes from "prop-types";
import * as components from "..";
import StarZoomSky from "../StarZoomSky";
import "./StoryLine.component.scss";

const StoryLine = props => {
  const {classes, storyline, transitionToStage} = props;
  const Component = components[storyline.component || "StoryLineSimple"]
  return (
    <div className={`c-StoryLine h-100${classes ? " " + classes : ""}`}>
      {storyline && storyline.background === "StarZoomSky" && <StarZoomSky theme="theme-black" />}
      <Component storyline={storyline} transitionToStage={transitionToStage} />
    </div>
  );
};

StoryLine.propTypes = {
  classes: PropTypes.string,
  storyline: PropTypes.object,
  transitionToStage: PropTypes.func
};

export default StoryLine;