import React from "react";
import PropTypes from "prop-types";
import {Provider} from "react-redux";
import {Route, Routes} from "react-router-dom";
import {Loader, Modal, Notification} from "./components";
import Landing from "./pages/Landing/Landing";

const Router = (props) => {
  const {store} = props;
  return (
    <Provider store={store}>
      <div className={`c-Router__global-events-interceptor h-100 w-100`}>
        <div className="c-Router__app-container overflow-hidden h-100 w-100 d-flex flex-column">
          <div className="c-Router__main-content d-flex h-100 w-100 overflow-auto">
            <Routes>
              <Route path="/" element={<Landing />} />
            </Routes>
          </div>
          {/* <Modal /> */}
          <Notification />
          <Loader />
        </div>
      </div>
    </Provider>
  );
};

Router.propTypes = {
  store: PropTypes.object,
};

export default Router;
