import {
  MARGIN1,
  Menu,
  Piece,
  Point,
  PolyPiece,
  isMiniature,
  alea,
  intAlea,
  arrayShuffle,
  lookForLoops,
  uploadFile
} from ".";

let autoStart = isMiniature();

class Puzzle {
  constructor(params) {
    // image - by url (src) or straight image object
    if (typeof params.img === "string") {
      this.image = new Image();
      this.image.src = params.img;
      this.wincb = params.wincb;
      this.connectSound = params.connectSound;
      this.image.addEventListener(
        "load",
        (function (obj) {
          return function () {
            obj.createPuzzle(params);
            if (params.preloadConfig) {
              obj.returnFunct(params.preloadConfig.blocks)();
            }
          };
        })(this)
      );
    } else {
      this.image = params.img;
      this.createPuzzle(params);
    }
  }

  createPuzzle(params) {
    //  let kx, ky, x, y, dx, dy, p1, p2, p3, brd, s1, s2, s3, s4, s5, s6, s7, s8, s9, concav, width, height, nx, ny;

    // we change width or height in order to keep the picture size ratio
    let wi = this.image.width; // from original picture
    let he = this.image.height;

    this.reqHeight = params.height; // requested height
    this.reqWidth = params.width;
    this.height = this.reqHeight - 2 * MARGIN1; // place left on screen including margin
    this.width = this.reqWidth - 2 * MARGIN1; //

    if (wi / he > this.width / this.height) {
      // actual picture "more horizontal" than game board
      this.height = (this.width * he) / wi;
    } else {
      this.width = (this.height * wi) / he;
    }
    // end change width or height

    // div Game - by name (id) or directly an object
    if (typeof params.idiv === "string") {
      this.divGame = document.getElementById(params.idiv);
    } else {
      this.divGame = params.idiv;
    }
    this.divGame.style.overflow = "visible";
    this.divGame.style.position = "relative";

    // divBoard
    if (!this.divBoard) {
      this.divBoard = document.createElement("div");
      this.divGame.appendChild(this.divBoard);
    }
    this.divBoard.style.overflow = "hidden";
    this.divBoard.style.position = "absolute";
    this.divBoard.style.left = 0;
    this.divBoard.style.top = 0;

    this.listeners = []; // table of eventListeners to remove

    /* provisional dimensions of the game, waiting for actual dimensions which depend
      on number of pieces
      */

    this.divGame.style.width = this.divBoard.style.width =
      this.width + 2 * MARGIN1 + "px";
    this.divGame.style.height = this.divBoard.style.height =
      this.height + 2 * MARGIN1 + "px";

    // canv for the moving PolyPiece and the full image
    if (!this.canvMobile) {
      this.canvMobile = document.createElement("canvas");
      this.divBoard.appendChild(this.canvMobile);
    }
    this.canvMobile.style.visibility = "visible";
    this.canvMobile.width = parseFloat(this.divBoard.style.width);
    this.canvMobile.height = parseFloat(this.divBoard.style.height);
    this.canvMobile.style.position = "absolute";
    this.canvMobile.style.top = "0px";
    this.canvMobile.style.left = "0px";

    this.canvMobile.style.zIndex = 1056;

    this.dCoupling = 10; // distance for pieces to couple together, in pixels (on each x and y axis)

    this.canvMobile
      .getContext("2d")
      .drawImage(
        this.image,
        0,
        0,
        wi,
        he,
        MARGIN1,
        MARGIN1,
        this.width,
        this.height
      );

    if (!this.menu) {
      this.menu = new Menu({
        parentDiv: this.divGame,
        idDivMenu: "divmenu",
        title: "MENU",
        lineOffset: 30,
        lineStep: 30,
        lines: [
          {text: "load image", func: this.loadImage()},
          {text: "12 piece", func: this.returnFunct(12)},
          {text: "25 piece", func: this.returnFunct(25)},
          {text: "50 piece", func: this.returnFunct(50)},
          {text: "100 piece", func: this.returnFunct(100)},
          {text: "200 piece", func: this.returnFunct(200)},
        ],
        nomenu: params.preloadConfig && params.preloadConfig.nomenu
      });
    }
    if (autoStart) {
      this.npieces = 25;
      this.next();
    }
  }

  returnFunct(nbpieces) {
    let puz = this;
    return function () {
      puz.menu.collapse();
      puz.npieces = nbpieces;
      puz.next();
    };
  }
  loadImage() {
    let puz = this;
    return function () {
      puz.menu.collapse();
      uploadFile(() => {}, {
        accept: "image/*",
        readMethod: "readAsDataURL",
        image: puz.image,
      });
    };
  }

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  next() {
    let nx,
      ny,
      np,
      dx,
      dy,
      kx,
      ky,
      x,
      y,
      p1,
      p2,
      p3,
      brd,
      s1,
      s2,
      s3,
      s4,
      s5,
      s6,
      s7,
      s8,
      s9,
      concav;
    /* parameters for the shape of pieces edges
     */

    let coeffDecentr = 0.12;

    this.canvMobile.style.visibility = "hidden"; // hide the full picture

    // evaluation of number of pieces

    this.computenxAndny();
    nx = this.nx;
    ny = this.ny;

    // re - evaluation of the dimensions of the picture, leaving a space for pieces on one side

    if (
      this.image.width / this.image.height <
      (this.reqWidth - 2 * MARGIN1) /
        (this.reqHeight - 2 * MARGIN1)
    ) {
      /* actual picture "more vertical" than available place
          leave place on the right side */
      this.width = ((this.reqWidth - 2 * MARGIN1) / (nx + 2)) * nx;
      this.height = (this.width / this.image.width) * this.image.height;
      if (this.height > this.reqHeight - 2 * MARGIN1) {
        this.height = this.reqHeight - 2 * MARGIN1;
        this.width = (this.height * this.image.width) / this.image.height;
      }
      this.freeSpace = 0; // place left on the right
    } else {
      /* actual picture "more horizontal" than available place
          leave place on the bottom side */
      this.height = ((this.reqHeight - 2 * MARGIN1) / (ny + 2)) * ny;
      this.width = (this.height / this.image.height) * this.image.width;
      if (this.width > this.reqWidth - 2 * MARGIN1) {
        this.width = this.reqWidth - 2 * MARGIN1;
        this.height = (this.width * this.image.height) / this.image.width;
      }
      this.freeSpace = 1; // place left under
    }

    let height = this.height,
      width = this.width;
    this.dx = dx = this.width / nx; // horizontal side of tiling
    this.dy = dy = this.height / ny; // vertical side of tiling

    /* adjust coupling distance to size of tiles */
    this.dCoupling = Math.max(10, Math.min(dx, dy) / 10);

    // "clean" the board
    while (this.divBoard.firstChild)
      this.divBoard.removeChild(this.divBoard.firstChild);
    // but keep the canvMobile
    this.divBoard.appendChild(this.canvMobile);

    this.canvMobile.width = this.reqWidth;
    this.divGame.style.width = this.divBoard.style.width =
      this.canvMobile.width + "px";

    this.canvMobile.height = this.reqHeight;
    this.divGame.style.height = this.divBoard.style.height =
      this.canvMobile.height + "px";

    // compute the shapes of the pieces

    /* first, place the corners of the pieces
        at some distance of their theorical position, except for edges
      */

    let corners = [];
    for (ky = 0; ky <= ny; ++ky) {
      corners[ky] = [];
      for (kx = 0; kx <= nx; ++kx) {
        corners[ky][kx] = new Point(
          (kx + alea(-coeffDecentr, coeffDecentr)) * dx,
          (ky + alea(-coeffDecentr, coeffDecentr)) * dy
        );
        if (kx === 0) corners[ky][kx].x = 0;
        if (kx === nx) corners[ky][kx].x = this.width;
        if (ky === 0) corners[ky][kx].y = 0;
        if (ky === ny) corners[ky][kx].y = this.height;
      } // for kx
    } // for ky

    // Array of raw pieces (straight sides)
    this.pieces = [];
    for (ky = 0; ky < ny; ++ky) {
      this.pieces[ky] = [];
      for (kx = 0; kx < nx; ++kx) {
        this.pieces[ky][kx] = np = new Piece(kx, ky);
        // top side
        if (ky === 0) {
          np.ts.points = [corners[ky][kx], corners[ky][kx + 1]];
          np.ts.type = "d";
        } else {
          np.ts = this.pieces[ky - 1][kx].bs;
        }
        // right side
        np.rs.points = [corners[ky][kx + 1], corners[ky + 1][kx + 1]];
        np.rs.type = "d";
        if (kx < nx - 1) {
          if (intAlea(2))
            // randomly twisted one one side of the side
            np.rs.twist(corners[ky][kx], corners[ky + 1][kx], 0.5, 1);
          else
            np.rs.twist(corners[ky][kx + 2], corners[ky + 1][kx + 2], 0.5, 1);
        }
        // left side
        if (kx === 0) {
          np.ls.points = [corners[ky][kx], corners[ky + 1][kx]];
          np.ls.type = "d";
        } else {
          np.ls = this.pieces[ky][kx - 1].rs;
        }
        // bottom side
        np.bs.points = [corners[ky + 1][kx], corners[ky + 1][kx + 1]];
        np.bs.type = "d";
        if (ky < ny - 1) {
          if (intAlea(2))
            // randomly twisted one one side of the side
            np.bs.twist(corners[ky][kx], corners[ky][kx + 1], 1, 0.5);
          else
            np.bs.twist(corners[ky + 2][kx], corners[ky + 2][kx + 1], 1, 0.5);
        }
      } // for kx
    } // for ky
    this.associateImage();
  } // function next

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // called when picture is loaded

  associateImage() {
    let kx, ky, kn, kp;
    let div, scale, he, wi, offsx, offsy, pc;

    // scale picture
    wi = this.image.width;
    he = this.image.height;

    if (wi / he > this.width / this.height) {
      // actual picture "more horizontal" than board
      scale = this.height / he;
      offsy = 0;
      offsx = (wi - this.width / scale) / 2; // offset in source picture
    } else {
      // actual picture "more (or equally)horizontal" than board
      scale = this.width / wi;
      offsx = 0;
      offsy = (he - this.height / scale) / 2; // offset in source picture
    }

    this.mech = {scale: scale, offsx: offsx, offsy: offsy}; // informations for scaling

    // creation of pieces
    // table of PolyPieces
    this.polyPieces = [];

    for (ky = 0; ky < this.ny; ky++) {
      for (kx = 0; kx < this.nx; kx++) {
        this.pieces[ky][kx].createDivPiece(this, scale, offsx, offsy);
        this.polyPieces.push(new PolyPiece(this.pieces[ky][kx], this));
      } // for kx
    } // for ky

    // random zindex for initial pieces
    arrayShuffle(this.polyPieces);
    this.evaluateZIndex();

    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        pc = this.polyPieces[kp].pieces[kn];

        this.divBoard.appendChild(pc.theDiv);
        switch (this.freeSpace) {
          case 0:
            pc.pTarget = new Point(
              this.reqWidth - (2.25 + Math.random() / 4) * this.dx,
              Math.random() * (this.height - this.dy) - this.dy
            );
            break;
          case 1:
            pc.pTarget = new Point(
              Math.random() * (this.width - this.dx) - this.dx,
              this.reqHeight - (2.25 + Math.random() / 4) * this.dy
            );
            break;
          default:
            pc.pTarget = null;
        } // switch
      } // for kn
    } // for kp
    window.setTimeout(
      (function (obj) {
        return function () {
          obj.launchAnimation();
        };
      })(this),
      1000
    );
  } // associateImage

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  addRemovableEventListener(event, funct) {
    this.divBoard.addEventListener(event, funct);
    this.listeners.push({event: event, funct: funct});
  } // addRemovableEventListener
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  removeAllListeners() {
    let a;
    while (this.listeners.length > 0) {
      a = this.listeners.pop();
      this.divBoard.removeEventListener(a.event, a.funct);
    } // while
  } // removeAllListeners()
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  launchAnimation() {
    this.anim = {cpt: autoStart ? 200 : 100};
    this.anim.tmr = window.setInterval(
      (function (puzz) {
        return function () {
          puzz.animate();
        };
      })(this),
      20
    );
  } // launchAnimation
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  animate() {
    let kp, kn, pc, act, cib;

    if (this.anim.cpt === 0) {
      window.clearInterval(this.anim.tmr);
      delete this.anim;

      this.evaluateZIndex();
      this.beginGame();

      return;
    }
    this.anim.cpt--;
    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        pc = this.polyPieces[kp].pieces[kn];
        act = pc.where();
        cib = pc.pTarget;
        pc.moveTo(
          new Point(
            (this.anim.cpt * act.x + cib.x) / (this.anim.cpt + 1),
            (this.anim.cpt * act.y + cib.y) / (this.anim.cpt + 1)
          )
        );
        if (this.anim.cpt === 0) {
          delete pc.pTarget;
        }
      } // for kn
    } // for kp
  } // animate

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // almost the same as 'animate, mais only to center the picture when game is over

  animateEnd() {
    let xcou, ycou;

    if (this.anim.cpt === 0) {
      window.clearInterval(this.anim.tmr);
      delete this.anim;
      return;
    }
    this.anim.cpt--;
    xcou = parseFloat(this.canvMobile.style.left);
    ycou = parseFloat(this.canvMobile.style.top);

    this.canvMobile.style.left =
      (this.anim.cpt * xcou + this.anim.xfin) / (this.anim.cpt + 1) + "px";
    this.canvMobile.style.top =
      (this.anim.cpt * ycou + this.anim.yfin) / (this.anim.cpt + 1) + "px";
  } // animateEnd

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // merges polyPieces[n2] and polyPieces[n1] into a new piece
  // removes those pieces and inserts nes one
  // re evaluates z-orders accordingly
  // return index of new polyPiece

  merge(n1, n2) {
    let nppiece, nbpieces, k;
    this.polyPieces[n1].merge(this.polyPieces[n2]); // merges pieces
    this.connectSound && this.connectSound.play();
    nppiece = this.polyPieces[n1]; // save new piece
    if (n1 > n2) {
      this.polyPieces.splice(n1, 1);
      this.polyPieces.splice(n2, 1);
    } else {
      this.polyPieces.splice(n2, 1);
      this.polyPieces.splice(n1, 1);
    }

    // will insert nes PolyPiece immediately before the first with less pieces
    nbpieces = nppiece.pieces.length;
    for (
      k = 0;
      k < this.polyPieces.length &&
      this.polyPieces[k].pieces.length >= nbpieces;
      k++
    ) {}
    // insert new
    this.polyPieces.splice(k, 0, nppiece);

    return k;
  } // merge

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  evaluateZIndex() {
    let kp, kn, z;
    z = 1;
    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        this.polyPieces[kp].pieces[kn].theDiv.style.zIndex = z++;
      } // for kn
    } // for kp
  } // evaluateZIndex

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // beginning of game
  beginGame() {
    // record offset between mouse coordinates et board origin

    let styl = getComputedStyle(this.divGame);
    this.mouseOffsX =
      this.divGame.getBoundingClientRect().left + parseFloat(styl.borderLeftWidth);
    this.mouseOffsY = this.divGame.getBoundingClientRect().top + parseFloat(styl.borderTopWidth);
    this.pieceMove = false; // no selected piece
    // set event listeners
    this.addRemovableEventListener(
      "mousedown",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {x: x, y: y, buttons: event.buttons, origin: "mouse"};
          puzzle.mouseDownGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "mouseup",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {x: x, y: y, buttons: event.buttons, origin: "mouse"};
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "mousemove",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {x: x, y: y, buttons: event.buttons, origin: "mouse"};
          puzzle.mouseMoveGame(newEvent);
        };
      })(this)
    );

    this.addRemovableEventListener(
      "touchstart",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          if (event.touches.length !== 1) return;
          let [x, y] = [event.touches[0].clientX, event.touches[0].clientY];
          let newEvent = {x: x, y: y, buttons: null, origin: "touch"};
          puzzle.mouseDownGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "touchend",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          let newEvent = {origin: "touch"};
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "touchcancel",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          let newEvent = {origin: "touch"};
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "touchmove",
      (function (puzzle) {
        return function (event) {
          event.preventDefault();
          if (event.touches.length !== 1) return;
          let [x, y] = [event.touches[0].clientX, event.touches[0].clientY];
          let newEvent = {x: x, y: y, buttons: null, origin: "touch"};
          puzzle.mouseMoveGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      "resize",
      (function (puzzle) {
        return function (event) {
          puzzle.canvMobile.width = puzzle.divGame.offsetWidth * 0.8;
          puzzle.canvMobile.height = puzzle.divGame.offsetHeight * 0.8;
        };
      })(this)
    );
  } // beginGame

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  // mouseDown during game
  mouseDownGame(event) {
    // ignore if not left button only
    if (event.origin === "mouse" && event.buttons !== 1) return;
    this.pieceMove = this.lookForPiece(event);
    if (this.pieceMove === false) return;
    this.emphasize(this.pieceMove.pp);
    // we will add to the 'this.pieceMove' object the offset between mousePosition and
    //   canvMobile position for proper movement of canvMobile when mouse moves

    this.pieceMove.offsx =
      event.x - this.mouseOffsX - parseFloat(this.canvMobile.style.left);
    this.pieceMove.offsy =
      event.y - this.mouseOffsY - parseFloat(this.canvMobile.style.top);
    this.divGame.style.cursor = "move";
  } // mouseDownGame
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  // mouseUp during game
  mouseUpGame(event) {
    let k, polyP, pc;

    this.divGame.style.cursor = "default";

    if (this.pieceMove === false) return;

    // hide the canvasMobile which was used for the moving piece
    let canvx = parseFloat(this.canvMobile.style.left);
    let canvy = parseFloat(this.canvMobile.style.top);
    this.canvMobile
      .getContext("2d")
      .clearRect(0, 0, this.canvMobile.width, this.canvMobile.height);

    // display again original pieces
    polyP = this.polyPieces[this.pieceMove.pp];
    for (k = 0; k < polyP.pieces.length; k++) {
      pc = polyP.pieces[k];
      pc.moveTo(
        new Point(this.dx * (pc.kx - 1) + canvx, this.dy * (pc.ky - 1) + canvy)
      );
      pc.theDiv.style.visibility = "visible";
    } // for k

    // check if moved piece is close enough of another to merge them
    //  check again with the result of the merge operation

    let idp = this.pieceMove.pp;
    let yesMerge = false,
      yesyesMerge = false;
    do {
      yesMerge = false;
      polyP = this.polyPieces[idp];
      for (k = 0; k < this.polyPieces.length; k++) {
        if (k === idp) continue; // don't check neighborhood with itself !
        if (polyP.ifNear(this.polyPieces[k])) {
          // yes !
          idp = this.merge(k, idp); // merge and keep track of index of merged piece
          yesMerge = true;
          yesyesMerge = true; // 2 pieces merging
          break; // out of  'for' loop
        } // if we found a piece
      } // for
    } while (yesMerge); // do it again if pieces werge merged

    // if no merging, move (if this.polypieces) the selected PolyPiece before
    // those with the same number of pieces

    if (!yesyesMerge) {
      let tmp = this.polyPieces[idp]; // memorize polyPiece
      this.polyPieces.splice(idp, 1); // remove from list
      for (
        k = idp;
        k < this.polyPieces.length &&
        this.polyPieces[k].pieces.length >= tmp.pieces.length;
        k++
      );
      this.polyPieces.splice(k, 0, tmp); // re-insert at the right place
    } // if no merging

    this.evaluateZIndex();
    this.pieceMove = false;

    // won ?
    if (this.polyPieces.length > 1) return; // no, continue

    // YES ! tell the player
    this.removeAllListeners();
    // normal image is re-drawn
    let ctx = this.canvMobile.getContext("2d");
    ctx.drawImage(
      this.image,
      this.mech.offsx,
      this.mech.offsy,
      this.width / this.mech.scale,
      this.height / this.mech.scale,
      0,
      0,
      this.width,
      this.height
    );
    this.anim = {
      cpt: 100,
      xorg: 0,
      yorg: 0,
      xfin: (this.reqWidth - this.dx * this.nx) / 2,
      yfin: (this.reqHeight - this.dy * this.ny) / 2,
    };

    this.anim.xorg =
      parseFloat(this.polyPieces[0].pieces[0].theDiv.style.left) + this.dx;
    this.anim.yorg =
      parseFloat(this.polyPieces[0].pieces[0].theDiv.style.top) + this.dy;
    this.canvMobile.style.left = this.anim.xorg + "px";
    this.canvMobile.style.top = this.anim.yorg + "px";

    // hide pieces
    for (k = 0; k < this.polyPieces[0].pieces.length; k++) {
      this.polyPieces[0].pieces[k].theDiv.style.visibility = "hidden";
    } // for k

    // launch final animation

    let dist = Math.sqrt(
      (this.anim.xorg - this.anim.xfin) * (this.anim.xorg - this.anim.xfin) +
        (this.anim.yorg - this.anim.yfin) * (this.anim.yorg - this.anim.yfin)
    );
    // we want a speed of about 100 pix / s
    // the time increment beeing of 20 ms, this leads to 100 * 0.02 = 2 pix / pass
    this.anim.cpt = dist / 2;
    // limit the duration to the range 0.25..2s, i.e.12..100 steps
    if (this.anim.cpt < 12) this.anim.cpt = 12;
    if (this.anim.cpt > 100) this.anim.cpt = 100;
    this.anim.cpt = Math.floor(this.anim.cpt);
    this.anim.tmr = window.setInterval(
      (function (puzz) {
        return function () {
          puzz.animateEnd();
        };
      })(this),
      20
    );
    this.wincb && this.wincb();
  } // mouseUpGame
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  // mouseMove during game
  mouseMoveGame(event) {
    if (this.pieceMove === false) return;

    // for the case where button was released out of 'good' area
    if (event.origin === "mouse") {
      if ((event.buttons & 1) === 0) {
        this.mouseUpGame(event);
        return;
      }
    }

    let x = event.x - this.mouseOffsX;
    let y = event.y - this.mouseOffsY;
    if (x < 2) x = 2;
    if (x > Math.floor(parseFloat(this.divBoard.style.width)) - 2)
      x = Math.floor(parseFloat(this.divBoard.style.width)) - 2;
    if (y < 2) y = 2;
    if (y > Math.floor(parseFloat(this.divBoard.style.height)) - 2)
      y = Math.floor(parseFloat(this.divBoard.style.height)) - 2;

    this.canvMobile.style.left = x - this.pieceMove.offsx + "px";
    this.canvMobile.style.top = y - this.pieceMove.offsy + "px";
  } // mouseMoveGame

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // searches the pieces whick was clicked on
  // event is the click event
  // returned value : (index of PolyPiece + piece) or false (if not on a piece)

  lookForPiece(event) {
    let kp, kn, z;
    let x = event.x - this.mouseOffsX;
    let y = event.y - this.mouseOffsY;
    for (kp = this.polyPieces.length - 1; kp >= 0; kp--) {
      for (kn = this.polyPieces[kp].pieces.length - 1; kn >= 0; kn--) {
        if (this.polyPieces[kp].pieces[kn].insidePiece(x, y))
          return {pp: kp, pc: kn};
      } // for kn
    } // for kp

    return false; // found nothing
  } // lookForPiece

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // emphasizes a polyPiece
  // its idividualpieces are masked (style.visibility = "hidden")
  // but they are collectively drawn on canvMobile
  // parameter : polyPiece index

  emphasize(npp) {
    let kbcl, kc, k;
    let ppc = this.polyPieces[npp]; // current PolyPiece
    let ctx = this.canvMobile.getContext("2d");
    let loops = lookForLoops(ppc.pieces);
    let edge;

    ctx.save();
    ctx.clearRect(0, 0, this.canvMobile.width, this.canvMobile.height);
    ctx.beginPath();
    for (kbcl = 0; kbcl < loops.length; kbcl++) {
      for (kc = 0; kc < loops[kbcl].length; kc++) {
        edge = loops[kbcl][kc];

        switch (edge.edge) {
          case 0:
            ppc.pieces[edge.kp].ts.drawPath(ctx, 0, 0, false, kc !== 0);
            break;
          case 1:
            ppc.pieces[edge.kp].rs.drawPath(ctx, 0, 0, false, kc !== 0);
            break;
          case 2:
            ppc.pieces[edge.kp].bs.drawPath(ctx, 0, 0, true, kc !== 0);
            break;
          case 3:
            ppc.pieces[edge.kp].ls.drawPath(ctx, 0, 0, true, kc !== 0);
            break;
          default:
            break;
        }
      } // for kc
    } // for kbcl;

    // make shadow
    ctx.fillStyle = "none";
    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 4;
    ctx.shadowOffsetY = 4;
    ctx.fill();

    // add image clipped by path
    ctx.clip("evenodd");
    // reset shadow else FF does not clip image
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;

    ctx.drawImage(
      this.image,
      this.mech.offsx,
      this.mech.offsy,
      this.width / this.mech.scale,
      this.height / this.mech.scale,
      0,
      0,
      this.width,
      this.height
    );

    // hide original PolyPiece
    for (k = 0; k < ppc.pieces.length; k++) {
      ppc.pieces[k].theDiv.style.visibility = "hidden";
    } // for k

    ctx.restore();

    // set picture position to hide previous one
    this.canvMobile.style.left =
      ppc.pieces[0].where().x - (ppc.pieces[0].kx - 1) * this.dx + "px";
    this.canvMobile.style.top =
      ppc.pieces[0].where().y - (ppc.pieces[0].ky - 1) * this.dy + "px";
    this.canvMobile.style.visibility = "visible";
  } // emphasize

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -

  // checks if p1 and p2 pieces are close to each other
  // dx is -1, 0 or 1 to check left, (top or bottom) or right side of p1
  // dy is -1, 0 or 1 to check top, (left or right) or bottom of p2

  near(p1, p2, dx, dy) {
    let ou1 = p1.where();
    let ou2 = p2.where();

    if (Math.abs(ou1.x - ou2.x + dx * this.dx) > this.dCoupling) return false;
    if (Math.abs(ou1.y - ou2.y + dy * this.dy) > this.dCoupling) return false;
    return true;
  } // near

  // fin class puzzle

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  /* computes the number of lines and columns of the puzzle,
        finding the best compromise between the requested number of pieces
        and a square shap for pieces
      */

  computenxAndny() {
    let kx,
      ky,
      width = this.image.width,
      height = this.image.height,
      npieces = this.npieces;
    let err,
      err2,
      errmin = 1e9;
    let ncv, nch;

    let nHPieces = Math.round(Math.sqrt((npieces * width) / height));
    let nVPieces = Math.round(npieces / nHPieces);

    /* based on the above estimation, we will try up to + / - 2 values
         and evaluate (arbitrary) quality criterion to keep best result
      */

    for (ky = 0; ky < 5; ky++) {
      ncv = nVPieces + ky - 2;
      for (kx = 0; kx < 5; kx++) {
        nch = nHPieces + kx - 2;
        err = (nch * height) / ncv / width;
        err = err + 1 / err - 2; // error on pieces dimensions ratio)
        err += Math.abs(1 - (nch * ncv) / npieces); // adds error on number of pieces

        if (err < errmin) {
          // keep smallest error
          errmin = err;
          this.nx = nch;
          this.ny = ncv;
        }
      } // for kx
    } // for ky
  }
}

export default Puzzle;
