import Immutable from "immutable"
import {combineReducers} from "redux"
import landingReducer from "./pages/Landing/reducer"
import { DISPATCH_MODAL } from "./global-actions";

const initialState = Immutable.fromJS({
    modal: {
        display: false
    }
});

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISPATCH_MODAL:
            return state.mergeDeep(action.payload)
        default:
            return state
    }
}

export default combineReducers({
    data: combineReducers({
        global: globalReducer,
        pages: combineReducers({
            landing: landingReducer
        })
    })
})
