import Helper from "./helper";

class Sequin {
  constructor(canvas, config) {
    const {dragSequins, gravitySequins, colors} = config;
    this.color = colors[Math.floor(Helper.randomRange(0, colors.length))].back;
    this.radius = Helper.randomRange(1, 2);
    this.dragSequins = dragSequins;
    this.gravitySequins = gravitySequins;
    this.position = {
      x: Helper.randomRange(canvas.width/2 - 40, canvas.width/2 + 40),
      y: Helper.randomRange(canvas.height, 30),
    };
    this.velocity = {
      x: Helper.randomRange(-6, 6),
      y: Helper.randomRange(-8, -12),
    };
  }

  update() {
    // apply forces to velocity
    this.velocity.x -= this.velocity.x * this.dragSequins;
    this.velocity.y = this.velocity.y + this.gravitySequins;

    // set position
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;
  }
}

export default Sequin;
