class Menu {
  constructor(params) {
    let parentDiv = params.parentDiv;
    if (typeof parentDiv == "string")
      parentDiv = document.getElementById(parentDiv);
    // div menu
    let divMenu = document.createElement("div");
    divMenu.setAttribute("id", params.idDivMenu);
    params.nomenu && (divMenu.style.display = "none");
    // title
    let dt = document.createElement("div");
    dt.classList.add("title");
    dt.appendChild(document.createTextNode(params.title));
    let that = this;
    dt.addEventListener("click", () => that.display());
    divMenu.appendChild(dt);
    this.list = [];
    for (let k = 0; k < params.lines.length; ++k) {
      dt = document.createElement("div");
      dt.classList.add("line");
      dt.appendChild(document.createTextNode(params.lines[k].text));
      dt.style.top = params.lineOffset + k * params.lineStep + "px";
      dt.addEventListener("click", params.lines[k].func);
      divMenu.appendChild(dt);
      this.list.push(dt);
    }
    divMenu.style.height =
      params.lineOffset + params.lines.length * params.lineStep + "px";
    this.divMenu = divMenu;
    parentDiv.appendChild(divMenu);
  } // Menu

  collapse() {
    this.divMenu.classList.remove("open");
  }

  display() {
    this.divMenu.classList.add("open");
  }
}

export default Menu;
