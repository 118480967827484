import React from "react";
import PropTypes from "prop-types";
import "./StarZoomSky.component.scss";

const StarZoomSky = props => {
  const {theme} = props;
  return <div className={`c-StarZoomSky overflow-hidden position-absolute w-100 h-100 top-0 left-0 ${theme}`}>
    <div className="signup-space position-absolute top-0 right-0 bottom-0 left-0">
      <div className="signup-stars"></div>
      <div className="signup-stars"></div>
      <div className="signup-stars"></div>
      <div className="signup-stars"></div>
      <div className="signup-stars"></div>
      <div className="signup-stars"></div>
    </div>
  </div>
};

StarZoomSky.propTypes = {
  theme: PropTypes.string
};

export default StarZoomSky;