import React, {memo, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import { createPropsSelector } from "reselect-immutable-helpers";
import PropTypes from "prop-types";
import {dispatchModal} from "../../global-actions";
import {getModal} from "../../global-selectors";
import "./Modal.component.scss";


const Modal = memo(props => {
  const {dispatchModal, modal} = props;
  const [content, setContent] = useState();
  const [modalClasses, setModalClasses] = useState();
  const [bodyClasses, setBodyClasses] = useState();

  useEffect(() => {
    if (modal && modal.data) {
      if (modal.data.classes !== undefined) {
        setModalClasses(modal.data.classes);
      }
      if (modal.data.bodyClasses !== undefined) {
        setBodyClasses(modal.data.bodyClasses);
      }
      if (modal.data.switchTo) {
        setTimeout(() => {
          setContent(modal && modal.data && modal.data.switchTo)
          setBodyClasses("");
        }, 150);
      } else {
        setContent(modal && modal.data && modal.data.body);
      }
    }
  }, [modal]);
  
  return (
    <div className={`c-Modal modal fade${modal && modal.display ? " show d-block" : " d-none"}
      ${modalClasses ? " " + modalClasses :""}`}
      tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content position-relative h-100 w-100">
          <button type="button" className="btn close position-absolute" onClick={() => dispatchModal({display: false})}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className={`modal-body p-0 h-100 w-100${bodyClasses ? " " + bodyClasses : ""}`}>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}, (prevProps, props) => {
  return JSON.stringify(prevProps.modal) === JSON.stringify(props.modal)
});

Modal.propTypes = {
  modal: PropTypes.object
};

const mapStateToProps = createPropsSelector({
  modal: getModal
})

const mapDispatchToProps = {
  dispatchModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);