import {createSelector} from "reselect";
import {createGetSelector} from "reselect-immutable-helpers";

const getData = ({data}) => data;

export const getGlobal = createSelector(
    getData,
    (dataState) => {
        return dataState.global
    }
);

export const getModal = createGetSelector(getGlobal, "modal");