import Confetto from "./confetto";
import Sequin from "./sequin";

let confetti = [];
let sequins = [];

const burst = (canvas, config) => {
  const {confettiCount, sequinCount, ...rest} = config;
  for (let i = 0; i < confettiCount; i++) {
    confetti.push(new Confetto(canvas, rest));
  }
  for (let i = 0; i < sequinCount; i++) {
    sequins.push(new Sequin(canvas, rest));
  }
};

const render = (ctx, canvas) => {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  confetti.forEach((confetto, index) => {
    let width = confetto.dimensions.x * confetto.scale.x;
    let height = confetto.dimensions.y * confetto.scale.y;
    ctx.translate(confetto.position.x, confetto.position.y);
    ctx.rotate(confetto.rotation);
    confetto.update();
    ctx.fillStyle =
      confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;
    ctx.fillRect(-width / 2, -height / 2, width, height);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    if (confetto.velocity.y < 0) {
      ctx.clearRect(canvas.width, canvas.height, 0, 0);
    }
  });

  sequins.forEach((sequin) => {
    ctx.translate(sequin.position.x, sequin.position.y);
    sequin.update();
    ctx.fillStyle = sequin.color;
    ctx.beginPath();
    ctx.arc(0, 0, sequin.radius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    if (sequin.velocity.y < 0) {
      ctx.clearRect(canvas.width, canvas.height, 0, 0);
    }
  });
  confetti.forEach(
    (confetto, index) =>
      confetto.position.y >= canvas.height && confetti.splice(index, 1)
  );
  sequins.forEach(
    (sequin, index) =>
      sequin.position.y >= canvas.height && sequins.splice(index, 1)
  );

  window.requestAnimationFrame(() => render(ctx, canvas));
};

export {burst, render};
