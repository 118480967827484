import React, { useState } from "react";
import PropTypes from "prop-types";
import Helper from "../../utils/helper";
import * as images from "../../static/images";
import "./GameSelector.component.scss";

const GameSelector = props => {
  const {gameLoadProgress, onGameSelect, selectedGame, user} = props;
  const [displayPills, setDisplayPills] = useState([]);
  const gameRenders = user && user.games ? user.games.map((game, index) => {
    !displayPills[index] && setTimeout(() => {
      displayPills[index] = "display";
      setDisplayPills([...displayPills]);
    }, (index + 1) * 200);
    const gameIcon = game.icon ? Helper.isUrl(game.icon) ? game.icon : images[game.icon] || images.Game : images.Game;
    return <div key={`c-GameSelector__pill-` + index} className={`c-GameSelector__gamepill d-inline-block me-5 mb-2 overflow-hidden p-3 align-top ${displayPills[index]}`} onClick={() => onGameSelect(game)}>
      <img src={gameIcon} alt={game.icon} className="w-100"/>
      <div className="text-center mt-2">{game.name}</div>
    </div>
  }) : <p>No games have been mapped yet</p>

  return gameLoadProgress && gameLoadProgress.progress === 100 ? <div className={`c-GameSelector${selectedGame ? " fade" : ""}`}>
      <h2>Welcome to Escaplify</h2>
      {user && user.games && user.games.length > 0 && <>
        <h6 className="mb-5">There are new requests for you, let's Dive in!</h6>
      </>}
      {gameRenders}
      <div className="note small mt-5"><b>Selecting one of the games above will open it in full screen and may include audio in the background</b></div>
    </div> : null
};

GameSelector.propTypes = {
  gameLoadProgress: PropTypes.object,
  onGameSelect: PropTypes.func,
  selectedGame: PropTypes.object,
  user: PropTypes.object
};

export default GameSelector;