import React from "react";
import PropTypes from "prop-types";
import Logo from "../../static/images/logo.jpg";
import User from "../../static/images/user.png";
import "./Header.component.scss";

const Header = (props) => {
  const {selectedGame} = props;
  return (
    <div className={`c-Header position-absolute left-0 right-0${selectedGame ? " d-none" : ""}`}>
      <header
        className="position-absolute left-0 right-0 py-3 z-1"
        role="banner"
      >
        <div className="container-xl d-lg-flex flex-items-center">
          <div className="d-md-flex d-md-flex justify-content-between align-items-center w-100">
            <a href="/" aria-label="Homepage"><img src={Logo} alt="Escaplify" width="32" /></a>
            <div className="font-mktg mt-3 mt-md-0">
              <input type="text" className="input-search me-3" placeholder="Search" />
              <img className="user-account-button" src={User} alt="User" width="32" role="button" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  selectedGame: PropTypes.object
};

export default Header;
