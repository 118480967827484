class Helper {
    static randomRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    static initConfettoVelocity(xRange, yRange) {
      const x = Helper.randomRange(xRange[0], xRange[1])
      const range = yRange[1] - yRange[0] + 1
      let y = yRange[1] - Math.abs(Helper.randomRange(0, range) + Helper.randomRange(0, range) - range)
      if (y >= yRange[1] - 1) {
        y += (Math.random() < .25) ? Helper.randomRange(1, 3) : 0
      }
      return {x: x, y: -y}
    }
}

export default Helper;