const mrandom = Math.random,
  mfloor = Math.floor,
  mhypot = Math.hypot,
  MARGIN1 = 5;
//-----------------------------------------------------------------------------
function isMiniature() {
  return window.location.pathname.includes("/fullcpgrid/");
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function alea(min, max) {
  // random number [min..max[ . If no max is provided, [0..min[

  if (typeof max == "undefined") return min * mrandom();
  return min + (max - min) * mrandom();
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function intAlea(min, max) {
  // random integer number [min..max[ . If no max is provided, [0..min[

  if (typeof max == "undefined") {
    max = min;
    min = 0;
  }
  return mfloor(min + (max - min) * mrandom());
} // intAlea

//-----------------------------------------------------------------------------
function arrayShuffle(array) {
  let k1, temp;
  for (let k = array.length - 1; k >= 1; --k) {
    k1 = Math.floor((k + 1) * Math.random());
    temp = array[k];
    array[k] = array[k1];
    array[k1] = temp;
  } // for k
  return array;
}

let options, callBack;

let elFile = document.createElement("input");
elFile.setAttribute("type", "file");
elFile.style.display = "none";
elFile.addEventListener("change", getFile);

function getFile() {
  if (this.files.length === 0) {
    returnLoadFile({fail: "no file"});
    return;
  }
  let file = this.files[0];
  let reader = new FileReader();

  reader.addEventListener("load", () => {
    if (options.image) options.image.src = reader.result;
    returnLoadFile({success: reader.result, file: file});
  });
  reader.addEventListener("abort", () => {
    returnLoadFile({fail: "abort"});
  });
  reader.addEventListener("error", () => {
    returnLoadFile({fail: "error"});
  });

  if (options.image || options.readMethod === "readAsDataURL")
    reader.readAsDataURL(this.files[0]);
  else reader.readAsText(this.files[0]);
} // getFile

function returnLoadFile(returnedValue) {
  callBack(returnedValue);
}
function uploadFile(ocallBack, ooptions) {
  options = ooptions;
  callBack = ocallBack;
  if (options.accept) elFile.setAttribute("accept", options.accept);
  else elFile.removeAttribute("accept");
  elFile.click();
};

function lookForLoops(tbCases) {
  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  // internal : checks if an edge given by kx, ky is common with another cell
  // returns true or false

  function edgeIsCommon(kx, ky, edge) {
    let k;
    switch (edge) {
      case 0:
        ky--;
        break; // top edge
      case 1:
        kx++;
        break; // right edge
      case 2:
        ky++;
        break; // bottom edge
      case 3:
        kx--;
        break; // left edge
      default:
        break;
    } // switch
    for (k = 0; k < tbCases.length; k++) {
      if (kx === tbCases[k].kx && ky === tbCases[k].ky) return true; // we found the neighbor
    }
    return false; // not a common edge
  } // function edgeIsCommon

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  // internal : checks if an edge given by kx, ky is in tbEdges
  // return index in tbEdges, or false

  function edgeIsInTbEdges(kx, ky, edge) {
    let k;
    for (k = 0; k < tbEdges.length; k++) {
      if (kx === tbEdges[k].kx && ky === tbEdges[k].ky && edge === tbEdges[k].edge)
        return k; // found it
    }
    return false; // not found
  } // function edgeIsInTbEdges

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -

  let tbLoops = []; // for the result
  let tbEdges = []; // set of edges which are not shared by 2 pieces of input
  let k;
  let kEdge; // to count 4 edges
  let lp; // for loop during its creation
  let currEdge; // current edge
  let tries; // tries counter
  let edgeNumber; // number of edge found during research
  let potNext;

  // table of tries

  let tbTries = [
    // if we are on edge 0 (top)
    [
      {dkx: 0, dky: 0, edge: 1}, // try # 0
      {dkx: 1, dky: 0, edge: 0}, // try # 1
      {dkx: 1, dky: -1, edge: 3}, // try # 2
    ],
    // if we are on edge 1 (right)
    [
      {dkx: 0, dky: 0, edge: 2},
      {dkx: 0, dky: 1, edge: 1},
      {dkx: 1, dky: 1, edge: 0},
    ],
    // if we are on edge 2 (bottom)
    [
      {dkx: 0, dky: 0, edge: 3},
      {dkx: -1, dky: 0, edge: 2},
      {dkx: -1, dky: 1, edge: 1},
    ],
    // if we are on edge 3 (left)
    [
      {dkx: 0, dky: 0, edge: 0},
      {dkx: 0, dky: -1, edge: 3},
      {dkx: -1, dky: -1, edge: 2},
    ],
  ];

  // create list of not shared edges (=> belong to boundary)
  for (k = 0; k < tbCases.length; k++) {
    for (kEdge = 0; kEdge < 4; kEdge++) {
      if (!edgeIsCommon(tbCases[k].kx, tbCases[k].ky, kEdge))
        tbEdges.push({
          kx: tbCases[k].kx,
          ky: tbCases[k].ky,
          edge: kEdge,
          kp: k,
        });
    } // for kEdge
  } // for k

  while (tbEdges.length > 0) {
    lp = []; // new loop
    currEdge = tbEdges[0]; // we begin with first available edge
    lp.push(currEdge); // add it to loop
    tbEdges.splice(0, 1); // remove from list of available sides
    do {
      for (tries = 0; tries < 3; tries++) {
        potNext = tbTries[currEdge.edge][tries];
        edgeNumber = edgeIsInTbEdges(
          currEdge.kx + potNext.dkx,
          currEdge.ky + potNext.dky,
          potNext.edge
        );
        if (edgeNumber === false) continue; // can't here
        // new element in loop
        currEdge = tbEdges[edgeNumber]; // new current edge
        lp.push(currEdge); // add it to loop
        tbEdges.splice(edgeNumber, 1); // remove from list of available sides
        break; // stop tries !
      } // for tries
      if (edgeNumber === false) break; // loop is closed
    } while (1); // do-while exited by break
    tbLoops.push(lp); // add this loop to loops list
  } // while tbEdges...
  return tbLoops;
} // function lookForLoops

export {
  MARGIN1,
  mrandom,
  alea,
  arrayShuffle,
  mfloor,
  mhypot,
  intAlea,
  isMiniature,
  lookForLoops,
  uploadFile
};
