import React from "react";
import PropTypes from "prop-types";
import "./ProgressBar.component.scss";

const ProgressBar = props => {
  const {progress: progressConfig} = props;
  const {content, progress} = progressConfig || {};
  const width = progress + "%";
  return (
    <div className={`c-ProgressBar w-100 h-100 d-flex justify-content-center align-items-center${progress === 100 ? " d-none" : ""}`}>
      <div className="c-ProgressBar__bg w-50">
        <div className="c-ProgressBar__loaderbar h-100 px-3 d-flex align-items-center" style={{width}}>{width}</div>
        <small className="position-absolute mt-2">{`Loading${content ? " " + content : ""}...`}</small>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.object
};

export default ProgressBar;