import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Helper from "../../utils/helper";
import "./StoryLineSimple.component.scss";

let prevContent, totaltimeout = 0;
const StoryLineSimple = props => {
  const {storyline, transitionToStage} = props;
  const [content, setContent] = useState();
  const storyTextRef = useRef();
  const speed = 100;

  useEffect(() => {
    storyline && storyline.text && storyline.text.forEach((obj, index) => {
      const timeout = prevContent ? (prevContent.content.length * speed) + prevContent.afterWait : 0
      totaltimeout += timeout;
      setTimeout(() => setContent(obj), totaltimeout);
      prevContent = obj;
    })
  }, [storyline]);

  const appendStoryText = content => {
    if (content && !content.viewed) {
      Helper.animatedType(storyTextRef.current, content, speed);
      content.viewed = true;
    }
  }

  return (
    <div
      className="c-StoryLineSimple d-flex justify-content-center align-items-center h-100 cursor-pointer"
      onClick={transitionToStage}>
      <span className="c-StoryLineSimple__text text-center" ref={storyTextRef}>
        {appendStoryText(content)}
      </span>
    </div>
  );
};

StoryLineSimple.propTypes = {
  storyline: PropTypes.object,
  transitionToStage: PropTypes.func
};

export default StoryLineSimple;