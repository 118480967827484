import React from "react";
import PropTypes from "prop-types";
import "./Clue.component.scss";

const Clue = props => {
  return (
    <div className="c-Clue">
      In Component Clue
    </div>
  );
};

Clue.propTypes = {

};

export default Clue;