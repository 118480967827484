import {Point} from ".";

class PolyPiece {
    constructor(initialPiece, puzz) {
        this.pckxmin = this.pckxmax = this.pckymin = this.pckymax = initialPiece;
        this.pieces = [initialPiece];
        this.puzzle = puzz;
      } // PolyPiece
      
      // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
      /*
        this method
          - adds pieces of otherPoly to this PolyPiece
          - adjusts coordinates of new pieces to make them consistent with this polyPiece
          - does not re - evaluate the z - index of the polyPieces
      */
      
      merge(otherPoly) {
        for (let k = 0; k < otherPoly.pieces.length; ++k) {
          this.pieces.push(otherPoly.pieces[k]);
          // watch leftmost, topmost... pieces
          if (otherPoly.pieces[k].kx < this.pckxmin.kx)
            this.pckxmin = otherPoly.pieces[k];
          if (otherPoly.pieces[k].kx > this.pckxmax.kx)
            this.pckxmax = otherPoly.pieces[k];
          if (otherPoly.pieces[k].ky < this.pckymin.ky)
            this.pckymin = otherPoly.pieces[k];
          if (otherPoly.pieces[k].ky > this.pckymax.ky)
            this.pckymax = otherPoly.pieces[k];
        } // for k
      
        this.moveTo(this.where()); // to set positions of new pieces
      
        // sort the pieces by increasing kx, ky
      
        this.pieces.sort(function (p1, p2) {
          if (p1.ky < p2.ky) return -1;
          if (p1.ky > p2.ky) return 1;
          if (p1.kx < p2.kx) return -1;
          if (p1.kx > p2.kx) return 1;
          return 0; // should not occur
        });
      }; // merge
      
      // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
      where() {
        return this.pieces[0].where();
      }; // where
      
      // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
      // the position of the PolyPiece is this of its 1st piece
      // the position of the others is evaluated on this basis
      
      moveTo(pnt) {
        this.pieces[0].moveTo(pnt);
        for (let kp = 1; kp < this.pieces.length; ++kp) {
          this.pieces[kp].moveTo(
            new Point(
              pnt.x + this.puzzle.dx * (this.pieces[kp].kx - this.pieces[0].kx),
              pnt.y + this.puzzle.dy * (this.pieces[kp].ky - this.pieces[0].ky)
            )
          );
        } // for kp
      };
      
      // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
      ifNear(otherPoly) {
        let p1, p2;
        for (let kp = 0; kp < this.pieces.length; ++kp) {
          p1 = this.pieces[kp];
          for (let kn = 0; kn < otherPoly.pieces.length; ++kn) {
            p2 = otherPoly.pieces[kn];
            // p2 above p1 ?
            if (
              p1.kx === p2.kx &&
              p1.ky === p2.ky + 1 &&
              this.puzzle.near(p1, p2, 0, -1)
            )
              return true;
            // p2 below p1 ?
            if (
              p1.kx === p2.kx &&
              p1.ky === p2.ky - 1 &&
              this.puzzle.near(p1, p2, 0, 1)
            )
              return true;
            // p2 left of p1 ?
            if (
              p1.kx - 1 === p2.kx &&
              p1.ky === p2.ky &&
              this.puzzle.near(p1, p2, -1, 0)
            )
              return true;
            // p2 right of p1 ?
            if (
              p1.kx + 1 === p2.kx &&
              p1.ky === p2.ky &&
              this.puzzle.near(p1, p2, +1, 0)
            )
              return true;
          } // for kn
        } // for kp
        return false; // no, not near
      };
}

export default PolyPiece;