import Helper from "./helper";

class Confetto {
  constructor(canvas, config) {
    const {dragConfetti, gravityConfetti, colors, terminalVelocity} = config;
    this.randomModifier = Helper.randomRange(0, 99);
    this.color = colors[Math.floor(Helper.randomRange(0, colors.length))];
    this.dragConfetti = dragConfetti;
    this.gravityConfetti = gravityConfetti;
    this.terminalVelocity = terminalVelocity;
    this.dimensions = {
      x: Helper.randomRange(5, 9),
      y: Helper.randomRange(8, 15),
    }
    this.position = {
      x: Helper.randomRange(canvas.width/2 - 40, canvas.width/2 + 40),
      y: Helper.randomRange(canvas.height, 30),
    }
    this.rotation = Helper.randomRange(0, 2 * Math.PI)
    this.scale = {
      x: 1,
      y: 1,
    }
    this.velocity = Helper.initConfettoVelocity([-9, 9], [6, 11])
  }

  update() {
    // apply forces to velocity
    this.velocity.x -= this.velocity.x * this.dragConfetti
    this.velocity.y = Math.min(this.velocity.y + this.gravityConfetti, this.terminalVelocity)
    this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()
    
    // set position
    this.position.x += this.velocity.x
    this.position.y += this.velocity.y

    // spin confetto by scaling y and set the color, .09 just slows cosine frequency
    this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09)    
  }
}

export default Confetto;