import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Confetti.component.scss";
import { burst, render } from "../../effects/confetti";
import Helper from "../../effects/confetti/helper";

const config = {
  confettiCount: 160,
  sequinCount: 80,
  gravityConfetti: 0.3,
  gravitySequins: 0.55,
  dragConfetti: 0.075,
  dragSequins: 0.02,
  terminalVelocity: 3,
  colors: [
    {front: "#7b5cff", back: "#6245e0"}, // Purple
    {front: "#b3c7ff", back: "#8fa5e5"}, // Light Blue
    {front: "#5c86ff", back: "#345dd1"}, // Darker Blue
  ],
}

const Confetti = (props) => {
  const {burstTimes, isOnForeFront} = props;
  const cRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (cRef.current && burstTimes) {
      for (let i = 0; i < burstTimes; i++) {
        burst(cRef.current, config);
      }
    }
  }, [burstTimes])

  useEffect(() => {
    if (cRef.current && containerRef.current) {
      const canvas = cRef.current;
      const ctx = canvas.getContext('2d')
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      render(ctx, canvas);
      window.addEventListener("resize", () => {
        canvas.width = containerRef.current?.offsetWidth
        canvas.height = containerRef.current?.offsetHeight
      })
    }
  }, [cRef, containerRef]);

  return <div
    className="c-Confetti position-absolute h-100 w-100 top-0"
    // onClick={() => burst(cRef.current, config)}
    ref={containerRef}>
    <canvas id="canvas" ref={cRef} style={{zIndex: isOnForeFront ? 1057 : 1}}></canvas>
  </div>;
};

Confetti.propTypes = {
  burstTimes: PropTypes.number,
  isOnForeFront: PropTypes.bool
};

export default Confetti;
