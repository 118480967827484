import React, {useEffect, useRef, useState} from "react";
import Puzzle from "../../puzzles/jigsaw/Puzzle";
import connect from "../../static/media/connect.wav";
import Helper from "../../utils/helper";
import * as images from "../../static/images";
import "./JigSaw.component.scss";

const JigSaw = props => {
  const {config, onComplete} = props;
  const audioRef = useRef();
  const gameRef = useRef();
  const [puzzle, setPuzzle] = useState();

  useEffect(() => {
    if (!puzzle && gameRef.current) {
      let img = config.image
        ? Helper.isUrl(config.image)
          ? config.image
          : images[config.image]
        : "https://wkz3w59.fr/ipcp/Mona_Lisa.jpg";

      const puzzle = new Puzzle({
        connectSound: audioRef.current,
        img,
        width: config.width || (gameRef.current.offsetWidth * 0.8),
        height: config.height || (gameRef.current.offsetHeight * 0.8),
        idiv: "jigsaw",
        preloadConfig: {blocks: config.blocks, nomenu: true},
        wincb: onComplete
      });
      setPuzzle(puzzle);
    }
  }, [puzzle, gameRef, config, onComplete]);

  return <>
  <div id="jigsaw" className="c-JigSaw h-100 w-100" ref={gameRef}/>
    <audio id="myAudio" ref={audioRef}>
      <source src={connect} type="audio/mp3" />
    </audio>
  </>
};

export default JigSaw;