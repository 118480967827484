import React from "react";
import PropTypes from "prop-types";
import "./Loader.component.scss";

const Loader = props => {
  return (
    <div className={`c-Loader position-fixed d-none`}>
      In Component Loader
    </div>
  );
};

Loader.propTypes = {

};

export default Loader;