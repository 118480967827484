import React from "react";
import PropTypes from "prop-types";
import "./Notification.component.scss";

const Notification = props => {
  return (
    <div className={`c-Notification position-fixed d-none`}>
      In Component Notification
    </div>
  );
};

Notification.propTypes = {

};

export default Notification;