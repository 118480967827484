class Helper {
  static animatedType(container, content, speed) {
    if (container) {
      function terminal(cl, i) {
        const div = document.createElement("div");
        div.className = cl;
        container.appendChild(div);
        const txt = content.content.split("");
        // let i = 0;
        (function display() {
          if (i < txt.length) {
            if (content.style) {
              Object.keys(content.style).forEach(key => {
                div.style[key] = content.style[key];
              })
            }
            div.innerHTML += txt[i].replace("\n", "<br />");
            ++i;
            setTimeout(display, speed || 100);
          }
        })();
      }
      terminal("tech-font", 0);
    }
  }

  static isUrl(str) {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    return str.match(regex);
  }

   static enterFullScreen(element) {
    if(element.requestFullscreen) {
      element.requestFullscreen();
    }else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();     // Firefox
    }else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();  // Safari
    }else if(element.msRequestFullscreen) {
      element.msRequestFullscreen();      // IE/Edge
    }
  };

  static exitFullscreen() {
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  static toHumanReadableTime(seconds) {
    let returnValue = "";
    const numberEnding = number => {
      return (number > 1) ? 's' : '';
    }
    const years = Math.floor(seconds / 31536000);
    if (years) {
      returnValue = years + ' year' + numberEnding(years);
    }
    const days = Math.floor((seconds %= 31536000) / 86400);
    if (days) {
      returnValue += days + ' day' + numberEnding(days);
    }
    const hours = Math.floor((seconds %= 86400) / 3600);
    if (hours) {
      returnValue += hours + ' hour' + numberEnding(hours);
    }
    const minutes = Math.floor((seconds %= 3600) / 60);
    if (minutes) {
      returnValue += minutes + ' minute' + numberEnding(minutes);
    }
    seconds = seconds % 60;
    if (seconds) {
      returnValue += seconds + ' second' + numberEnding(seconds);
    }
    return returnValue ? returnValue : 'less than a second';
  }
}

export default Helper;
