import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import {Challenge, ChallengeComplete, StoryLine} from "..";
import Helper from "../../utils/helper";
import * as images from "../../static/images";
import "./Stage.component.scss";

const transformProps = {
  timeAllocated: Helper.toHumanReadableTime
};


const StagePlay = (props) => {
  const {dispatchModal, stageConfig, onChallengeComplete} = props;
  const [classes, setClasses] = useState();

  useEffect(() => {
    setClasses("show");
  }, []);

  const stageBackground =
    stageConfig &&
    stageConfig.background &&
    (Helper.isUrl(stageConfig.background)
      ? stageConfig.background
      : images[stageConfig.background]);

  const markerRenders =
    stageConfig &&
    stageConfig.clues &&
    stageConfig.clues
      .map((clue, index) => {
        return (
          clue.state === "UNLOCKED" && (
            <span
              key={"Clue-" + index}
              className="c-StagePlay__imageMarker position-absolute"
              style={{top: clue.ylocation + "%", left: clue.xlocation + "%"}}
              onClick={() =>
                dispatchModal({
                  display: true,
                  data: {
                    classes: "challenge",
                    body: (
                      <Challenge
                        config={clue.challenge}
                        onChallengeComplete={(completionType, timeToComplete) =>
                          onChallengeComplete(clue, completionType, timeToComplete)
                        }
                      />
                    ),
                  },
                })
              }
            >
              ?
            </span>
          )
        );
      })
      .filter((m) => m);

  return (
    <div className={`c-StagePlay h-100${classes ? " " + classes : ""}`}>
      <div className="countdown">
        <Countdown date={Date.now() + (stageConfig.timeAllocated * 1000)} onComplete={() => {}} />
      </div>
      {stageBackground && (
        <img
          className="c-StagePlay__image h-100 w-100"
          src={stageBackground}
          alt="Stage Background"
        />
      )}
      {markerRenders}
    </div>
  );
};

const Stage = (props) => {
  const {dispatchModal, selectedGame} = props;
  const [storylineClasses, setStorylineClasses] = useState();
  const [stageConfig, setStageConfig] = useState();
  const [stageStarted, proceedToStage] = useState();

  useEffect(() => {
    selectedGame &&
      selectedGame.stages &&
      setStageConfig(selectedGame.stages[0]);
  }, [selectedGame]);

  const transitionToStage = () => {
    setStorylineClasses("fade");
    setTimeout(() => proceedToStage(true), 1000);
  };

  const onChallengeComplete = (clue, completionType, timeToComplete) => {
    if (completionType === "giveup") {
      dispatchModal({display: false})
    } else {
      let unlockedClues = [];
      const clueIds = clue.unlockclues;
      if (clueIds) {
        let stageConfigClone = {...stageConfig};
        clueIds.forEach((id) => {
          const clue = stageConfigClone.clues.find((clue) => clue.id === id);
          unlockedClues.push(clue);
          clue.state = "UNLOCKED";
        });
        setStageConfig(stageConfigClone);
      }
      setTimeout(
        () =>
          dispatchModal({
            display: true,
            data: {
              classes: "challenge",
              bodyClasses: " fade zoomout",
              switchTo: (
                <ChallengeComplete
                  unlockedClues={unlockedClues}
                  dispatchModal={dispatchModal}
                  timeToComplete={timeToComplete}
                />
              ),
            },
          }),
        clue.transitionTimeoutOnComplete || 0
      );
    }
  };

  const processStoryLine = () => {
    const stl = (stageConfig && stageConfig.storyline && stageConfig.storyline.text) || [];
    stl.forEach(stlPart => {
      const stlContent = stlPart.content;
      if (stlContent.indexOf("{{") > -1) {
        let variable = stlContent.substring(stlContent.indexOf("{{") + 2, stlContent.indexOf("}}"));
        if (variable in transformProps) {
          variable = transformProps[variable](stageConfig[variable]);
        }
        const newStl = stlContent.substring(0, stlContent.indexOf("{{")).concat(variable).concat(stlContent.substring(stlContent.indexOf("}}") + 2));
        stlPart.content = newStl;
      }
    })
    return stageConfig.storyline;
  }

  return stageConfig ? (
    <div className="c-Stage">
      <div className="c-Stage__imageContainer h-100 w-100 position-absolute top-0 left-0">
        {!stageStarted ? (
          <StoryLine
            storyline={processStoryLine()}
            transitionToStage={transitionToStage}
            classes={storylineClasses}
          />
        ) : (
          <StagePlay
            stageConfig={stageConfig}
            dispatchModal={dispatchModal}
            onChallengeComplete={onChallengeComplete}
          />
        )}
      </div>
    </div>
  ) : null;
};

Stage.propTypes = {
  dispatchModal: PropTypes.func,
  selectedGame: PropTypes.object,
};

export default Stage;
