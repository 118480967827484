import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {dispatchModal} from "../../global-actions";
import {Stage} from "..";
import Helper from "../../utils/helper";
import welcomeMusic from "../../static/media/music.mp3";
import { Back } from "../../static/images";
import "./Game.component.scss";

const Game = props => {
  const {dispatchModal, selectedGame, gameRef, onBack} = props;
  const audioRef = useRef();

  useEffect(() => {
    if (selectedGame) {
      audioRef.current && audioRef.current.play();
      gameRef && gameRef.current && Helper.enterFullScreen(gameRef.current)
    }
  }, [selectedGame, gameRef]);

  return (
    <div className={`c-Game${selectedGame ? "" : " fade h-0 overflow-hidden"}`}>
      <img className="back-button position-absolute" src={Back} alt="Go Back" width="32" onClick={() => {
        Helper.exitFullscreen();
        audioRef.current && audioRef.current.pause();
        audioRef.current.currentTime = 0;
        onBack(null);
        dispatchModal({display: false, data: {body: null}})
      }} />
      <Stage selectedGame={selectedGame} dispatchModal={dispatchModal} />
      <audio ref={audioRef} autoPlay loop>
        <source src={welcomeMusic} type="audio/mp3" />
      </audio>
    </div>
  );
};

Game.propTypes = {
  gameRef: PropTypes.object,
  dispatchModal: PropTypes.func,
  started: PropTypes.string,
  user: PropTypes.object
};


const mapDispatchToProps = {
  dispatchModal
}

export default connect(null, mapDispatchToProps)(Game);
