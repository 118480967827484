import React from "react";
import PropTypes from "prop-types";
import "./Time.component.scss";

const Time = props => {
  return (
    <div className="c-Time">
      In Component Time
    </div>
  );
};

Time.propTypes = {

};

export default Time;