class Point {
    constructor(x, y) {
        this.x = Number(x);
        this.y = Number(y);
    }
      
    copy() {
        return new Point(this.x, this.y);
    };
}

export default Point;