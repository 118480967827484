import {Point, mhypot} from ".";

class Segment {
  constructor(p1, p2) {
    this.p1 = new Point(p1.x, p1.y);
    this.p2 = new Point(p2.x, p2.y);
  }

  length() {
    const dx = this.p1.x - this.p2.x;
    const dy = this.p1.y - this.p2.y;
    return mhypot(dx, dy);
  }
  dx() {
    return this.p2.x - this.p1.x;
  }
  dy() {
    return this.p2.y - this.p1.y;
  }

  // returns a point at a given distance of p1, positive direction beeing towards p2

  pointOnRelative(coeff) {
    // attention if segment length can be 0
    const dx = this.p2.x - this.p1.x;
    const dy = this.p2.y - this.p1.y;
    return new Point(this.p1.x + coeff * dx, this.p1.y + coeff * dy);
  }
}

export default Segment;
